import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { PUBLIC_NODE_ENV, PUBLIC_VERSION } from '$env/static/public';

Sentry.init({
  dsn: 'https://b70a6b42cb99b3659eefd2b1796b0874@o1136374.ingest.us.sentry.io/4507951231008768',
  tracesSampleRate: 0.05, // Trace 5% of requests
  release: 'aomstats@' + PUBLIC_VERSION,
  enabled: PUBLIC_NODE_ENV === 'production'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
